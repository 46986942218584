import { useShow } from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  DateField,
  NumberField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

import { Dialog } from "@mui/material";
import React, { useState } from "react";

export const SurillmiShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const handleClickOpen = (imageUrl: string) => {
    setCurrentImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const record = data?.data;

  console.log(record?.gpt);

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <TextField value={record?.id} />
        <Typography variant="body1" fontWeight="bold">
          Created At
        </Typography>
        <DateField value={record?.created_at} />
        <Typography variant="body1" fontWeight="bold">
          Prompt
        </Typography>
        <TextField value={record?.prompt} />
        <Typography variant="body1" fontWeight="bold">
          Posts
        </Typography>
        <NumberField value={record?.posts ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Num Words
        </Typography>
        <NumberField value={record?.numWords ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Language
        </Typography>
        <TextField value={record?.language} />
        <Typography variant="body1" fontWeight="bold">
          gpt
        </Typography>
        <div>
          {record?.gpt?.posts.map((post: any, index: any) => (
            <div key={index}>
              <h2>{post.title}</h2>
              <img
                src={record?.images?.images[index].url}
                alt={record?.images?.images[index]}
                style={{ width: "25%", height: "auto", cursor: "pointer" }}
                onClick={() =>
                  handleClickOpen(record?.images?.images[index].url)
                }
              />
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
              >
                <img
                  src={currentImage}
                  alt={currentImage}
                  style={{ width: "100%", height: "auto" }}
                />
              </Dialog>
              <p>{post.post_content}</p>
              <audio controls>
                <source
                  src={record?.audios?.audio[index].translationUrl}
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
              <h3>Translated:</h3>
              <p>
                ({post.translation.language}) {post.translation.translation}
              </p>
              <audio controls>
                <source
                  src={record?.audios?.audio[index].url}
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
            </div>
          ))}
        </div>
      </Stack>
    </Show>
  );
};
