import React from "react";
import { useDataGrid, EditButton, ShowButton, DeleteButton, List, DateField } from "@refinedev/mui";
import { TextField } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation } from "@refinedev/core";

export const CallbackList = () => {
  const { dataGridProps } = useDataGrid({
    liveMode: "auto"
  });
  const { show } = useNavigation();

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 50,
        renderCell: function render({ value }) {
          return <TextField value={value} />;
        },
      },
      {
        field: "ip",
        headerName: "Ip",
        minWidth: 120,
      },
      {
        field: "external_ip",
        headerName: "External Ip",
        minWidth: 120,
      },
      {
        field: "city",
        headerName: "City",
        minWidth: 50,
      },
      {
        field: "country",
        headerName: "Country",
        minWidth: 50,
      },
      {
        field: "lat_long",
        headerName: "Lat Long",
        minWidth: 150,
      },
      {
        field: "country_emoji",
        headerName: "Emoji",
        minWidth: 50,
      },
      {
        field: "hostname",
        headerName: "Hostname",
        minWidth: 50,
      },
      {
        field: "os",
        headerName: "Os",
        minWidth: 50,
      },
      {
        field: "arch",
        headerName: "Arch",
        minWidth: 50,
      },
      {
        field: "users",
        headerName: "Users",
        minWidth: 50,
      },
      {
        field: "boot_time",
        headerName: "Boot Time",
        type: "number",
        minWidth: 50,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        onRowClick={({ id }) => {
          show("callbacks", id);
        }}
      />
    </List>
  );
};
