import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";

export const AgentGroupCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm();

  const { autocompleteProps: tagsConfiguredAgentsAutocompleteProps } =
    useAutocomplete({
      resource: "configured_agents",
    });

  // const { autocompleteProps: tagsUsersAutocompleteProps } = useAutocomplete({
  //   resource: "users",
  // });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      ></Box>

      <TextField
        {...register("name", {
          required: "This field is required",
        })}
        error={!!(errors as any)?.name}
        helperText={(errors as any)?.name?.message}
        margin="normal"
        fullWidth
        InputLabelProps={{ shrink: true }}
        type="text"
        label="Name"
        name="name"
      />
      <TextField
        {...register("description", {
          required: "This field is required",
        })}
        error={!!(errors as any)?.description}
        helperText={(errors as any)?.description?.message}
        margin="normal"
        fullWidth
        InputLabelProps={{ shrink: true }}
        type="text"
        label="Description"
        name="description"
      />

      <Controller
        control={control}
        name="configured_agents"
        rules={{ required: "This field is required" }}
        // eslint-disable-next-line
        defaultValue={[] as any}
        render={({ field }) => (
          <Autocomplete
            {...tagsConfiguredAgentsAutocompleteProps}
            {...field}
            multiple
            onChange={(_, value) => {
              field.onChange(value?.map((item: any) => item?.id ?? item));
            }}
            getOptionLabel={(item) => {
              return (
                tagsConfiguredAgentsAutocompleteProps?.options?.find(
                  (p) => p?.id?.toString() === (item?.id ?? item)?.toString()
                )?.callsign ?? ""
              );
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined ||
              option.id.toString() === (value?.id ?? value)?.toString()
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Call Sign"
                margin="normal"
                variant="outlined"
                error={!!(errors as any)?.callsign}
                helperText={(errors as any)?.callsign?.message}
                required
              />
            )}
          />
        )}
      />
    </Create>
  );
};
