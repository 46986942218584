import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";

export const TaskingCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm();

  const { autocompleteProps: tagsAutocompleteProps } = useAutocomplete({
    resource: "configured_agents",
    
    onSearch: (value) => [
      {
          field: "callsign",
          operator: "contains",
          value,
      },
    ],
    
  });

  const { autocompleteProps: tagsTaskStringAutocompleteProps } =
    useAutocomplete({
      resource: "tasking",
      pagination: {
        pageSize: 50,
      },
    });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Controller
          control={control}
          name="configured_agents"
          rules={{ required: "This field is required" }}
          // eslint-disable-next-line
          defaultValue={[] as any}
          render={({ field }) => (
            <Autocomplete
              {...tagsAutocompleteProps}
              {...field}
              multiple
              onChange={(_, value) => {
                field.onChange(value?.map((item: any) => item?.id ?? item));
              }}
              getOptionLabel={(item) => {
                return (
                  tagsAutocompleteProps?.options?.find(
                    (p) => p?.id?.toString() === (item?.id ?? item)?.toString()
                  )?.callsign ?? ""
                );
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                option.id.toString() === (value?.id ?? value)?.toString()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Call Sign"
                  margin="normal"
                  variant="outlined"
                  error={!!(errors as any)?.callsign}
                  helperText={(errors as any)?.callsign?.message}
                  required
                />
              )}
            />
          )}
        />
        <TextField
          {...register("task", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.task}
          helperText={(errors as any)?.task?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Task"
          name="task"
        />
        <TextField
          {...register("arg", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.task}
          helperText={(errors as any)?.task?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Arg"
          name="arg"
        />
        {/* <Controller
          control={control}
          name="tasking"
          rules={{ required: "This field is required" }}
          // eslint-disable-next-line
          defaultValue={[] as any}
          render={({ field }) => (
            <Autocomplete
              {...tagsTaskStringAutocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value?.map((item: any) => item?.id ?? item));
              }}
              getOptionLabel={(item) => {
                return (
                  tagsTaskStringAutocompleteProps?.options?.find(
                    (p) => p?.id?.toString() === (item?.id ?? item)?.toString()
                  )?.arg ?? ""
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Arg"
                  margin="normal"
                  variant="outlined"
                  error={!!(errors as any)?.callsign}
                  helperText={(errors as any)?.callsign?.message}
                  required
                />
              )}
            />
          )}
        /> */}
      </Box>
    </Create>
  );
};
