import React from "react";
import { useDataGrid, EditButton, TagField, ShowButton, DeleteButton, List } from "@refinedev/mui";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation } from "@refinedev/core";

export const AgentGroupList = () => {
  const { dataGridProps } = useDataGrid();
  const { show } = useNavigation();

  const { data: configuredAgentsData, isLoading: configuredAgentsIsLoading } =
    useMany({
      resource: "configured_agents",
      ids: [].concat(
        ...(dataGridProps?.rows?.map((item: any) => item?.configured_agents) ??
          [])
      ),
      queryOptions: {
        enabled: !!dataGridProps?.rows,
      },
    });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 200,
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 200,
      },
      {
        field: "configured_agents",
        headerName: "Configured Agents",
        minWidth: 700,
        renderCell: function render({ value }) {
          return configuredAgentsIsLoading ? (
            <>Loading...</>
          ) : (
            <>
              {value?.map((item: any, index: number) => (
                <TagField
                  key={index}
                  value={
                    configuredAgentsData?.data?.find(
                      (resourceItems) => resourceItems.id === item
                    )?.callsign || "DELETED"
                  }
                />
              ))}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        //disable hightlighting of the row when clicked
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        columns={columns}
        autoHeight
        onRowClick={({ id }) => {
          show("agent_group", id);
        }}
      />
    </List>
  );
};
