import { useShow, useOne, useNavigation } from "@refinedev/core";
import {
  Show,
  TagField,
  TextFieldComponent as TextField,
  DateField,
  NumberField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

import { AgentMap } from "../../components/map/";

function SwitchComponent(props: any) {
  switch (props.task) {
    case 0:
      return <h1> Command {props.resp}</h1>;
    case 1:
      return (
        <img
          src={`data:image/jpeg;base64,${props.resp}`}
          style={{ width: "75%", height: "75%" }}
        />
      );
    case 2:
      const json = JSON.parse(props.resp);
      if (json && json.faces_info) {
        return (
          <div>
            <h1> JSONM Latitude: {json.latlong}</h1>
            <h2> Total Faces: {json.total_faces}</h2>
            <h2> Estimated Crossing: {json.estimated_crossing}</h2>
            {json.faces_info.map((faceInfo: any) => (
              <div key={faceInfo.filename}>
                <h3>{faceInfo.filename}</h3>
                <img
                  src={`data:image/jpeg;base64,${faceInfo.base_64}`}
                  style={{ width: "75%", height: "75%" }}
                />
              </div>
            ))}
          </div>
        );
      }
      return <h1>Unknown Response</h1>;
    default:
      return <h1> UKNOWN {props.resp}</h1>;
  }
}

export const AgentTaskShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const { show } = useNavigation();
  const record = data?.data;

  const { data: configuredAgentData, isLoading: configuredAgentIsLoading } =
    useOne({
      resource: "configured_agents",
      id: record?.configured_agent_id || "",
      queryOptions: {
        enabled: !!record,
      },
    });

  const { data: callBackData, isLoading: callBackDataIsLoading } = useOne({
    resource: "callbacks",
    id: record?.callback_id || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <AgentMap
          mapCoords={[
            {
              coords:
                record?.resp && record?.task === 2
                  ? JSON.parse(record?.resp).latlong
                  : callBackData?.data.lat_long,
              tooltip:
                record?.resp && record?.task === 2
                  ? "Estimated: " +
                    JSON.parse(record?.resp).estimated_crossing +
                    " \nFaces:" +
                    JSON.parse(record?.resp).total_faces
                  : record?.id?.toString() ?? "cp",
              icon: "test",
            },
          ]}
        />
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <TextField value={record?.id} />
        <Typography variant="body1" fontWeight="bold">
          callback_id
        </Typography>
        <TagField
          key={record?.callback_id}
          value={record?.callback_id}
          onClick={() => {
            show("callbacks", record?.callback_id);
          }}
        />
        <Typography variant="body1" fontWeight="bold">
          Configured Agent
        </Typography>
        <TagField
          value={(configuredAgentData?.data.callsign as string) ?? ""}
          onClick={() => {
            show("configured_agents", configuredAgentData?.data?.id as string);
          }}
        />

        <Typography variant="body1" fontWeight="bold">
          Created At
        </Typography>
        <DateField value={record?.created_at} />
        <Typography variant="body1" fontWeight="bold">
          Task
        </Typography>
        <NumberField value={record?.task ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Arg
        </Typography>
        <TextField value={record?.arg} />
        <Typography variant="body1" fontWeight="bold">
          Resp
        </Typography>
        <SwitchComponent task={record?.task} resp={record?.resp} />
      </Stack>
    </Show>
  );
};
