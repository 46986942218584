import React, { useState, useEffect } from "react";
import { supabaseClient } from "utility";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  MarkdownField,
} from "@refinedev/mui";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation } from "@refinedev/core";

const CallbacksTasksTable = () => {
  const [rows, setRows] = useState([]);
  const { show } = useNavigation();
  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabaseClient.rpc(
        "get_all_callbacks_tasks_count_dates"
      );
      if (error) {
        console.error(error);
      } else {
        setRows(data.map((row: any, index: any) => ({ ...row, id: index })));
      }
    };
    fetchData();
  }, []);
  console.log("rows", rows);
  const columns = [
    // {
    //   field: "agent_id",
    //   headerName: "Agent ID",
    //   minWidth: 300,
    // },
    {
      field: "callsign",
      headerName: "Callsign",
      minWidth: 200,
    },
    {
      field: "callback_count",
      headerName: "Callback Count",
      minWidth: 150,
      type: "number",
    },
    {
      field: "last_callback_date",
      headerName: "Last Callback Date",
      minWidth: 200,
      type: "date",
    },
    {
      field: "task_count",
      headerName: "Task Count",
      minWidth: 150,
      type: "number",
    },
    {
      field: "last_task_date",
      headerName: "Last Task Date",
      minWidth: 200,
      type: "date",
    },
  ];

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "last_callback_date", sort: "desc" }],
          },
        }}
        rows={rows}
        columns={columns}
        onRowClick={({ row }) => {
          show("configured_agents", row.agent_id);
        }}
      />
    </div>
  );
};

export default CallbacksTasksTable;
