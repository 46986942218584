import { useShow, useMany } from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  DateField,
  NumberField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvent,
  useMap,
} from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import Leaflet from "leaflet";

import { AgentMap } from "../../components/map/";

export const newicon = new Leaflet.Icon({
  iconUrl: require("./marker-iconlg.png"), // require the path to the asset
});

export const CallbackShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  //const animateRef = useRef(false);
  const record = data?.data;

  //const [lat, long] = getLatLong("27.9614, -82.4597");
  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <AgentMap
          mapCoords={[
            {
              coords: record?.lat_long,
              tooltip: record?.id?.toString() ?? "cp",
              icon: "test",
            },
          ]}
        />

        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <TextField value={record?.id} />
        <Typography variant="body1" fontWeight="bold">
          Created At
        </Typography>
        <TextField value={record?.created_at} />
        <Typography variant="body1" fontWeight="bold">
          Ip
        </Typography>
        <TextField value={record?.ip} />
        <Typography variant="body1" fontWeight="bold">
          External Ip
        </Typography>
        <TextField value={record?.external_ip} />
        <Typography variant="body1" fontWeight="bold">
          City
        </Typography>
        <TextField value={record?.city} />
        <Typography variant="body1" fontWeight="bold">
          Country
        </Typography>
        <TextField value={record?.country} />
        <Typography variant="body1" fontWeight="bold">
          Lat Long
        </Typography>
        <TextField value={record?.lat_long} />
        <Typography variant="body1" fontWeight="bold">
          Country Emoji
        </Typography>
        <TextField value={record?.country_emoji} />
        <Typography variant="body1" fontWeight="bold">
          Hostname
        </Typography>
        <TextField value={record?.hostname} />
        <Typography variant="body1" fontWeight="bold">
          Os
        </Typography>
        <TextField value={record?.os} />
        <Typography variant="body1" fontWeight="bold">
          Arch
        </Typography>
        <TextField value={record?.arch} />
        <Typography variant="body1" fontWeight="bold">
          Users
        </Typography>
        <TextField value={record?.users} />
        <Typography variant="body1" fontWeight="bold">
          Boot Time
        </Typography>
        <NumberField value={record?.boot_time ?? ""} />
      </Stack>
    </Show>
  );
};
