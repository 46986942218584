import React, { useState, useEffect } from "react";
import { supabaseClient } from "utility";
import CalendarHeatmap from "react-calendar-heatmap";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  MarkdownField,
} from "@refinedev/mui";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation } from "@refinedev/core";
import "react-calendar-heatmap/dist/styles.css";

interface SummaryCount {
  time_period: string;
  callback_count: number;
  task_count: number;
}

const SummaryCountsTable: React.FC = () => {
  const [rows, setRows] = useState([]);

  const columns = [
    { field: "time_period", headerName: "Time Period", width: 200 },
    { field: "callback_count", headerName: "Callback Count", width: 200 },
    { field: "task_count", headerName: "Task Count", width: 200 },
  ];

  const fetchSummaryCounts = async () => {
    const { data, error } = await supabaseClient.rpc("get_summary_counts");

    if (error) {
      console.error("Error fetching summary counts:", error);
      return;
    }

    if (data) {
      const formattedRows = data.map((item: any, index: any) => ({
        id: index,
        ...item,
      }));
      setRows(formattedRows);
    }
  };

  useEffect(() => {
    fetchSummaryCounts();
  }, []);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default SummaryCountsTable;
