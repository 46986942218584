import { useShow, useMany, useNavigation } from "@refinedev/core";
import HeatMap from "../../components/heatmap";
import {
  Show,
  TagField,
  TextFieldComponent as TextField,
  DateField,
  NumberField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

import { useState, useMemo, useEffect } from "react";

import React from "react";
import { List, Card, CardHeader, Grid } from "@mui/material";

import { useList } from "@refinedev/core";

import { AgentMap } from "../../components/map/";
import { ImageUploadAndCompare } from "../../components/faceRecog/";

import { supabaseClient } from "utility";

import { TaskComponentList } from "../../components/tasks/";
export const AgentGroupShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const { show } = useNavigation();

  const record = data?.data;
  //retrieves the information on the configured agents
  const { data: tagsData, isLoading: tagsIsLoading } = useMany({
    resource: "configured_agents",
    ids: record?.configured_agents || [],
    queryOptions: {
      enabled: !!record,
    },
  });

  const [rows, setRows] = useState([]);

  //retrieves the information on the callbacks associated with agents in this group
  const agentIDs = useMemo(
    () => tagsData?.data?.map((agent: any) => agent.id) ?? [],
    [tagsData]
  );

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabaseClient.rpc("get_latest_lat_long", {
        agent_ids: agentIDs,
      });
      if (error) {
        console.error(error);
      } else {
        setRows(data.map((row: any, index: any) => ({ ...row, id: index })));
      }
    };
    //lists agentIDs as a dependency for this hook
    if (agentIDs.length > 0) {
      fetchData();
    }
  }, [agentIDs]);

  const { data: dataTaskList } = useList({
    resource: "agent_task",
    liveMode: "auto",

    filters: [
      {
        field: "configured_agent_id",
        operator: "in",
        value: record?.configured_agents || [],
      },
    ],
  });

  const latLongArray = dataTaskList
    ? dataTaskList.data.map((item: any) => ({
        tooltip:
          "Estimated: " +
          JSON.parse(item.resp).estimated_crossing +
          " \nFaces:" +
          JSON.parse(item.resp).total_faces,
        coords: JSON.parse(item.resp).latlong,
        icon: "marker-iconlg.png",
      }))
    : rows.map((item: any) => ({
        tooltip: item.agent_id,
        coords: item.latest_lat_long,
        icon: "marker-iconlg.png",
      }));

  return (
    <List>
      <h1>{record?.name}</h1>
      <Grid container columns={20} spacing={2}>
        <Grid item xs={15} sm={15} md={20} lg={20} xl={20}>
          <AgentMap mapCoords={latLongArray} />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <ImageUploadAndCompare />
        </Grid>
        <Grid item xs={7} md={7} xl={7}>
          <Typography variant="body1" fontWeight="bold">
            Id
          </Typography>
          <TextField value={record?.id} />
          <Typography variant="body1" fontWeight="bold">
            Created At
          </Typography>
          <DateField value={record?.created_at} />

          {tagsIsLoading ? (
            <>Loading...</>
          ) : (
            <>
              <Stack direction="row" spacing={1}>
                {tagsData?.data?.map((tags: any) => (
                  <TagField
                    key={tags?.callsign}
                    value={tags?.callsign}
                    onClick={() => {
                      show("configured_agents", tags?.id);
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
        </Grid>

        <Grid item xs={10} md={10} xl={10}>
          Callbacks Heatmap
          <HeatMap ids={agentIDs}></HeatMap>
        </Grid>
      </Grid>
      <TaskComponentList filter={agentIDs}></TaskComponentList>
    </List>
  );
};
