import React, { useState, useEffect } from "react";
import { supabaseClient } from "utility";
import CalendarHeatmap from "react-calendar-heatmap";
import HeatMaps from "@uiw/react-heat-map";

import Tooltipper from "@uiw/react-tooltip";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  MarkdownField,
} from "@refinedev/mui";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation, useNotification } from "@refinedev/core";

//import "react-calendar-heatmap/dist/styles.css";
const today = new Date();
function shiftDate(date: any, numDays: any) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

export type IDs = {
  ids: string[];
};

const HeatMap: React.FC<IDs> = ({ ids }) => {
  const { open } = useNotification();
  const [rows, setRows] = useState([]);
  const { show } = useNavigation();
  useEffect(() => {
    console.log("ids", ids.length);
    const fetchData = async () => {
      const { data, error } =
        ids.length === 0
          ? await supabaseClient.rpc("get_daily_callbacks_count")
          : await supabaseClient.rpc("get_daily_callbacks_count", {
              agent_ids: [ids],
            });
      if (error) {
        console.error(error);
      } else {
        setRows(data.map((row: any, index: any) => ({ ...row, id: index })));
      }
    };
    fetchData();
  }, [ids]);
  console.log("rows", rows);

  const updatedData = rows.map((obj: any) => {
    obj.count = obj.callback_count;
    return obj;
  });

  console.log("data", updatedData);

  const [range, setRange] = useState(5);
  const [selected, setSelected] = useState("");
  return (
    <div>
      <div>
        <HeatMaps
          weekLabels={undefined}
          monthLabels={undefined}
          value={updatedData}
          width={650}
          style={{ color: "#D3D3D3" }}
          startDate={shiftDate(today, -200)}
          endDate={shiftDate(today, 0)}
          rectProps={{
            rx: 0,
          }}
          rectRender={(props, data) => {
            // if (!data.count) return <rect {...props} />;
            if (selected !== "") {
              console.log("selected", selected);
            }
            return (
              <Tooltipper
                key={props.key}
                content={`date: ${data.date} count: ${data.count || 0}`}
              >
                <rect
                  {...props}
                  onClick={() => {
                    setSelected(data.date === selected ? "" : data.date);
                    open?.({
                      type: "success",
                      message: `date: ${data.date} count: ${data.count || 0}`,
                      description: "Callback Count:",
                      key: "unique-id",
                    });
                  }}
                />
              </Tooltipper>
            );
          }}
        />
      </div>
    </div>
  );
};

export default HeatMap;
