import { HeadlessInferencer } from "@refinedev/inferencer/headless";
import { MuiInferencer } from "@refinedev/inferencer/mui";

import React from "react";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  TagField,
  DeleteButton,
  List,
  DateField,
} from "@refinedev/mui";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation } from "@refinedev/core";
type ICallbackFilter = {
  filter?: string[];
};

export const TaskComponentList: React.FC<ICallbackFilter> = ({ filter }) => {
  const { dataGridProps } = useDataGrid({
    resource: "agent_task",
    liveMode: "auto",

    filters: {
      permanent: [
        {
          field: "configured_agent_id",
          operator: "in",
          value: filter,
        },
      ],
    },
  });
  const { data: tagsData, isLoading: tagsIsLoading } = useMany({
    resource: "configured_agents",
    ids: filter || [],
  });

  const { show } = useNavigation();
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      // {
      //   field: "agent_id",
      //   headerName: "agent_id",
      //   minWidth: 300,
      //   renderCell: function render({ value }) {
      //     return tagsIsLoading ? (
      //       <>Loading...</>
      //     ) : (
      //       <>
      //         <TagField
      //           value={
      //             tagsData?.data?.find((value) => value.id === value.agent_id)
      //               ?.callsign || "DELETED"
      //           }
      //         />
      //         ;
      //       </>
      //     );
      //   },
      // },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 200,
        renderCell: function render({ value }) {
          return <TagField value={value} />;
        },
      },
      {
        field: "task",
        headerName: "Task",
        type: "number",
        minWidth: 200,
      },
      {
        field: "arg",
        headerName: "Arg",
        minWidth: 200,
      },
      {
        field: "resp",
        headerName: "Resp",
        minWidth: 200,
        renderCell: function render({ row }) {
          if (row.task === 1) {
            return (
              <img
                src={`data:image/jpeg;base64,${row.resp}`}
                style={{ width: "50px", height: "50px" }}
              />
            );
          } else if (row.task === 2 && JSON.parse(row.resp).faces_info) {
            return (
              <div>
                {JSON.parse(row.resp).faces_info.map(
                  (
                    face: { base_64: string; filename: string },
                    index: number
                  ) => (
                    <img
                      key={index}
                      src={`data:image/jpeg;base64,${face.base_64}`}
                      alt={face.filename}
                      style={{ width: "50px", height: "50px" }}
                    />
                  )
                )}
              </div>
            );
          } else {
            return <span>Unknown</span>;
          }
        },
      },
    ],
    []
  );

  return (
    <DataGrid
      {...dataGridProps}
      //disable hightlighting of the row when clicked
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
      }}
      columns={columns}
      autoHeight
      onRowClick={({ id }) => {
        show("agent_task", id);
      }}
    />
  );
};
