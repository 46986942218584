import { useShow } from "@refinedev/core";
import { Show, TextFieldComponent as TextField, DateField, MarkdownField } from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const ReferenceAgentShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <TextField value={record?.id} />
        <Typography variant="body1" fontWeight="bold">
          OS
        </Typography>
        <TextField value={record?.os} />
        <Typography variant="body1" fontWeight="bold">
          Arch
        </Typography>
        <TextField value={record?.arch} />
        <Typography variant="body1" fontWeight="bold">
          Bin Size
        </Typography>
        <TextField value={record?.binSize} />

        <Typography variant="body1" fontWeight="bold">
          FileInfo
        </Typography>
        <TextField value={record?.file_info} />

        <Typography variant="body1" fontWeight="bold">
          Updated At
        </Typography>
        <DateField value={record?.updated_at} />
        {/* <Typography variant="body1" fontWeight="bold">
          Enc Config
        </Typography>
        <MarkdownField value={record?.enc_config} /> */}
        <Typography variant="body1" fontWeight="bold">
          Storage Key
        </Typography>
        <TextField value={record?.storage_key} />
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <TextField value={record?.id} />

        <Typography variant="body1" fontWeight="bold">
          Strings Output
        </Typography>
        <TextField
          value={record?.wordlist.map((txt: string) => (
            <p>{txt.slice(0, 100)}</p>
          ))}
        />
      </Stack>
    </Show>
  );
};
