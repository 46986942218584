import React from "react";
import { useMany, useNavigation } from "@refinedev/core";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
} from "@refinedev/mui";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

export const SurillmiList = () => {
  const { dataGridProps } = useDataGrid();
  const { show } = useNavigation();
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        minWidth: 50,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created At",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "prompt",
        flex: 1,
        headerName: "Prompt",
        minWidth: 200,
      },
      {
        field: "posts",
        flex: 1,
        headerName: "Posts",
        type: "number",
        minWidth: 200,
      },
      {
        field: "numWords",
        flex: 1,
        headerName: "Num Words",
        type: "number",
        minWidth: 200,
      },
      {
        field: "language",
        flex: 1,
        headerName: "Language",
        minWidth: 200,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <ShowButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        onRowClick={({ id }) => {
          show("surillmi", id);
        }}
      />
    </List>
  );
};
