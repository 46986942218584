import { HeadlessInferencer } from "@refinedev/inferencer/headless";
import { MuiInferencer } from "@refinedev/inferencer/mui";

import React from "react";

import {
  useDataGrid,
  EditButton,
  TagField,
  ShowButton,
  DeleteButton,
  List,
  DateField,
} from "@refinedev/mui";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { TextField } from "@mui/material";
import { useMany, useNavigation } from "@refinedev/core";

export type CallbackFilter = {
  filter: string[];
};

export const CallbacksComponentList: React.FC<CallbackFilter> = ({
  filter,
}) => {
  const { dataGridProps } = useDataGrid({
    resource: "callbacks",
    liveMode: "auto",

    filters: {
      permanent: [
        {
          field: "configured_agent_id",
          operator: "in",
          value: filter,
        },
      ]
    }
  });

  const { show } = useNavigation();
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 200,
        renderCell: function render({ value }) {
          return <TagField value={value} />;
        },
      },
      {
        field: "ip",
        headerName: "Ip",
        minWidth: 120,
      },
      {
        field: "external_ip",
        headerName: "External Ip",
        minWidth: 120,
      },
      {
        field: "city",
        headerName: "City",
        minWidth: 50,
      },
      {
        field: "country",
        headerName: "Country",
        minWidth: 50,
      },
      {
        field: "lat_long",
        headerName: "Lat Long",
        minWidth: 150,
      },
      {
        field: "country_emoji",
        headerName: "Emoji",
        minWidth: 50,
      },
      {
        field: "hostname",
        headerName: "Hostname",
        minWidth: 50,
      },
      {
        field: "os",
        headerName: "Os",
        minWidth: 50,
      },
      {
        field: "arch",
        headerName: "Arch",
        minWidth: 50,
      },
    ],
    []
  );

  return (
    <DataGrid
      {...dataGridProps}
      //disable hightlighting of the row when clicked
      
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
      }}
      columns={columns}
      autoHeight
      onRowClick={({ id }) => {
        show("callbacks", id);
      }}
    />
  );
};
