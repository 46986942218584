import { useEffect } from "react";
import { useShow, useMany } from "@refinedev/core";
import {
  Show,
  DateField,
  NumberField,
  TextFieldComponent as TextField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import "leaflet/dist/leaflet.css";
import { LatLngBounds } from "leaflet";
import { Polyline, CircleMarker, Tooltip } from "react-leaflet";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvent,
  useMap,
} from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import Leaflet from "leaflet";

// function returnIcon(url: string): L.Icon {
//   return new Leaflet.Icon({
//     iconUrl: require(url), // require the path to the asset
//   });
// }

function getLatLong(latlong: string): [number, number] {
  if (!latlong) {
    return [0, 0];
  }
  const lat = latlong.split(",")[0];
  const long = latlong.split(",")[1];

  return [Number(lat), Number(long)];
}

interface CenterMapProps {
  bounds: LatLngBounds;
}

const CenterMap: React.FC<CenterMapProps> = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds.isValid()) {
      map.fitBounds(bounds, { padding: [25, 25] });
    }
  }, [map, bounds]);

  return null;
};

export const newicon = new Leaflet.Icon({
  iconUrl: require("./marker-iconlg.png"), // require the path to the asset
  iconAnchor: [25, 25],
});

// Your custom icons
export const netlifyicon = new Leaflet.Icon({
  iconUrl: require("./netlify-marker.png"),
  iconAnchor: [25, 25],
});

export const supabaseicon = new Leaflet.Icon({
  iconUrl: require("./supabase-marker.png"),
  iconAnchor: [25, 25],
});

export const flyioicon = new Leaflet.Icon({
  iconUrl: require("./flyio-marker.png"),
  iconAnchor: [25, 25],
});

export type MapPoint = {
  coords: string;
  tooltip: string;
  icon: string;
};

export type MapCoords = {
  mapCoords: MapPoint[];
};

export const AgentMap: React.FC<MapCoords> = ({ mapCoords }) => {
  const coordPoints = mapCoords.map((point) => getLatLong(point.coords));
  const bounds = Leaflet.latLngBounds(coordPoints);

  const infrastructure = [
    {
      coords: "32.8771,-80.013",
      tooltip: "onimgiri.com",
      icon: netlifyicon,
    },
    {
      coords: "43.6532,-79.3832",
      tooltip: "zkcsuwcfwaibjxifynxp.supabase.co",
      icon: supabaseicon,
    },
    {
      coords: "41.8781,-87.6298",
      tooltip: "itamae.fly.dev",
      icon: flyioicon,
    },
  ];

  const polylinePoints = [
    getLatLong(infrastructure[2].coords),
    getLatLong(infrastructure[1].coords),
    getLatLong(infrastructure[0].coords),
  ];

  return (
    <Stack gap={1}>
      <MapContainer
        center={getLatLong("0,0")}
        bounds={bounds}
        zoom={10}
        scrollWheelZoom={true}
        dragging={true}
        style={{ height: 536 }}
      >
        <CenterMap bounds={bounds} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {mapCoords.map((coord) => {
          const latLong = getLatLong(coord.coords);

          return (
            <Marker position={latLong} icon={newicon}>
              <Tooltip>{coord.tooltip}</Tooltip>
            </Marker>
          );
        })}

        {infrastructure.map((coord) => {
          const latLong = getLatLong(coord.coords);

          return (
            <Marker position={latLong} icon={coord.icon}>
              <Popup>{coord.tooltip}</Popup>
            </Marker>
          );
        })}

        {/* Polyline to connect all points */}
        <Polyline positions={polylinePoints} color="black" />

        {mapCoords.map((coord) => {
          return (
            <Polyline
              positions={[
                getLatLong(infrastructure[2].coords),
                getLatLong(coord.coords),
              ]}
              color="grey"
              dashArray="5,10"
            />
          );
        })}
      </MapContainer>
    </Stack>
  );
};
