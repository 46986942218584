import {
  useShow,
  useMany,
  useNotification,
  useList,
  useNavigation,
} from "@refinedev/core";
import React from "react";

import {
  Show,
  TextFieldComponent as TextField,
  DateField,
  TagField,
  List,
  MarkdownField,
  useDataGrid,
  UrlField,
} from "@refinedev/mui";

import { GridColumns, DataGrid } from "@mui/x-data-grid";
import { Typography, Stack } from "@mui/material";
import { Card, CardHeader, Grid } from "@mui/material";
import { MuiInferencer } from "@refinedev/inferencer/mui";

import { CallbacksComponentList } from "../../components/callbacks/";

import { TaskComponentList } from "../../components/tasks/";

import { AgentMap } from "../../components/map/";

export const ConfiguredAgentShow = () => {
  const { dataGridProps } = useDataGrid();
  const { show } = useNavigation();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const { open } = useNotification();
  const record = data?.data;
  const { data: callbacksData, isLoading: configuredAgentsIsLoading } = useList(
    {
      liveMode: "auto",

      onLiveEvent: (event) => {
        // Put your own logic based on event

        open?.({
          type: "success",
          message: `${event.type} : ${event.payload}`,
          description: "Callback detected",
          key: "unique-id",
        });
      },

      resource: "callbacks",

      filters: [
        {
          field: "configured_agent_id",
          operator: "eq",
          value: record?.id,
        },
      ],

      sorters: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    }
  );
  const callbacks = callbacksData?.data ?? [];

  return (
    <Show isLoading={isLoading}>
      <Grid container columns={20} spacing={2}>
        <Grid item xs={15} sm={15} md={16} lg={15} xl={15}>
          <AgentMap
            mapCoords={[
              {
                coords:
                  callbacks[0] === undefined ? "0,0" : callbacks[0].lat_long,
                tooltip: record?.id?.toString() ?? "cp",
                icon: "test",
              },
            ]}
          />
        </Grid>
        <Grid item xs={5} md={5} xl={5}>
          <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">
              Reference Agent
            </Typography>
            <TagField
              value={record?.reference_id}
              onClick={() => {
                show("reference_agents", record?.reference_id);
              }}
            />
            <Typography variant="body1" fontWeight="bold">
              interval
            </Typography>
            <TagField value={record?.interval} />
            <Typography variant="body1" fontWeight="bold">
              arch
            </Typography>
            <TagField value={record?.arch} />
            <Typography variant="body1" fontWeight="bold">
              OS
            </Typography>
            <TagField value={record?.os} />
            <Typography variant="body1" fontWeight="bold">
              Callsign
            </Typography>
            <TagField value={record?.callsign} />
            <Typography variant="body1" fontWeight="bold">
              Updated At
            </Typography>
            <TagField value={record?.updated_at} />
            {/* <Typography variant="body1" fontWeight="bold">
          Enc Config
        </Typography>
        <MarkdownField value={record?.enc_config} /> */}
            <Typography variant="body1" fontWeight="bold">
              Download Url
            </Typography>
            <UrlField value={record?.download_url} title="Download">
              Download
            </UrlField>
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body1" fontWeight="bold">
        callback
      </Typography>
      <TagField value={record?.callback_address} />

      <Typography variant="body1" fontWeight="bold">
        Tasks
      </Typography>
      <TaskComponentList
        filter={([record?.id] as string[]) || [" "]}
      ></TaskComponentList>
      <Typography variant="body1" fontWeight="bold">
        Callbacks
      </Typography>
      <CallbacksComponentList
        filter={([record?.id] as [string]) || ([" "] as [string])}
      ></CallbacksComponentList>
    </Show>
  );
};
