import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Button, Autocomplete } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";

export const ConfiguredAgentCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm();

  const { autocompleteProps } = useAutocomplete({
    resource: "reference_agents",
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      ></Box>
      <Controller
        control={control}
        name="reference_id"
        rules={{ required: "This field is required" }}
        // eslint-disable-next-line
        defaultValue={"" as any}
        render={({ field }) => (
          <Autocomplete
            {...autocompleteProps}
            onChange={(_, value) => {
              field.onChange(value.id);
            }}
            getOptionLabel={(item) => {
              return (
                (autocompleteProps?.options?.find(
                  (p) => p?.id?.toString() === (item?.id ?? item)?.toString()
                )?.id ?? "") +
                (autocompleteProps?.options?.find(
                  (p) => p?.id?.toString() === (item?.id ?? item)?.toString()
                )?.file_info ?? "")
              );
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined ||
              option.id.toString() === (value?.id ?? value)?.toString()
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Reference Agent"
                margin="normal"
                variant="outlined"
                error={!!(errors as any)?.file_info}
                helperText={(errors as any)?.file_info?.message}
                required
              />
            )}
          />
        )}
      />
      {/* 
                    DatePicker component is not included in "@pankod/refine-mui" package.
                    To use a <DatePicker> component, you can follow the official documentation for Material UI.
                    
                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                */}
      <TextField
        {...register("callback_address", {
          required: "This field is required",
        })}
        error={!!(errors as any)?.callback_address}
        helperText={(errors as any)?.callback_address?.message}
        margin="normal"
        defaultValue={"https://itamae.fly.dev"}
        fullWidth
        InputLabelProps={{ shrink: true }}
        type="text"
        label="Callback Url"
        name="callback_address"
      />{" "}
      {/* <TextField
          {...register("kill_date", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.kill_date}
          helperText={(errors as any)?.kill_date?.message}
          margin="normal"
          fullWidth
          defaultValue={60000}
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Kill Time (Epoch)"
          name="kill_date"
        /> */}
      <TextField
        {...register("interval", {
          required: "This field is required",
        })}
        error={!!(errors as any)?.interval}
        helperText={(errors as any)?.interval?.message}
        margin="normal"
        fullWidth
        InputLabelProps={{ shrink: true }}
        defaultValue={60000}
        type="number"
        label="Callback Interval (milliseconds)"
        name="interval"
      />
    </Create>
  );
};
