import { useList, useNavigation } from "@refinedev/core";
import React, { useState, useRef, useEffect } from "react";
import * as faceapi from "face-api.js";
import { Box, Button, LinearProgress } from "@mui/material";

export const ImageUploadAndCompare: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [matchedTask, setMatchedTask] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [match, setMatch] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const { show } = useNavigation();

  useEffect(() => {
    loadModels();
  }, []);

  const loadModels = async () => {
    await Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri("/weights"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/weights"),
      faceapi.nets.ssdMobilenetv1.loadFromUri("/weights"),
    ]);
    // console.log("All models loaded");
  };
  const onFileChange = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === "file") {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file) setSelectedFile(URL.createObjectURL(file));
        }
      }
    }
  };

  useEffect(() => {
    if (selectedFile) {
      onSubmit({ preventDefault: () => {} } as any); // pass an empty event object
    }
  }, [selectedFile]);

  const { data: dataTaskList } = useList({
    resource: "agent_task",
    liveMode: "auto",
    filters: [
      {
        field: "task",
        operator: "eq",
        value: 2,
      },
    ],
  });

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    const queryImage = imgRef.current;
    if (queryImage) {
      const queryDescriptor = await faceapi
        .detectSingleFace(queryImage)
        .withFaceLandmarks()
        .withFaceDescriptor();

      if (queryDescriptor && dataTaskList) {
        let totalFaces = 0;
        for (const task of dataTaskList.data) {
          totalFaces += JSON.parse(task.resp).faces_info.length;
        }
        let currentFaceIndex = 0;

        event.preventDefault();
        setProgress(0);

        for (const task of dataTaskList.data) {
          for (const face of JSON.parse(task.resp).faces_info) {
            setProgress((currentFaceIndex / totalFaces) * 100);
            const refImage = await faceapi.fetchImage(
              `data:image/jpeg;base64,${face.base_64}`
            );
            const refDescriptor = await faceapi
              .detectSingleFace(refImage)
              .withFaceLandmarks()
              .withFaceDescriptor();
            if (refDescriptor) {
              const dist = faceapi.euclideanDistance(
                refDescriptor.descriptor,
                queryDescriptor.descriptor
              );
              if (dist < 0.6 && task.id !== undefined) {
                setProgress(100);
                setMatchedTask(task.id.toString());
                return;
              }
            }
            currentFaceIndex++;
          }
        }
      }
    }
  };

  const navigateToTask = () => {
    if (matchedTask) {
      show("agent_task", matchedTask);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClickBox = () => {
    fileInputRef.current?.click();
  };

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Box
          onClick={onClickBox}
          onDrop={onFileChange}
          onDragOver={onDragOver}
          style={{
            height: 100,
            width: 100,
            border: "1px dashed gray",
            backgroundImage: `url(${selectedFile})`,
            backgroundSize: "cover",
          }}
        >
          Drop or Click here to find matches
        </Box>
        <input
          ref={fileInputRef}
          type="file"
          onChange={onFileInputChange}
          style={{ display: "none" }}
        />
        {/* Invisible img element for face-api.js to work with */}
        {selectedFile && (
          <img
            ref={imgRef}
            src={selectedFile}
            alt="Uploaded file"
            style={{ display: "none" }}
          />
        )}
      </form>

      <LinearProgress
        style={{ height: 20, width: 100 }}
        variant="determinate"
        value={progress}
      />
      {/* {selectedFile && (
        <img ref={imgRef} src={selectedFile} alt="Uploaded file" />
      )} */}
      {matchedTask && (
        <p>
          <Button onClick={navigateToTask}>Match Found</Button>
        </p>
      )}
    </div>
  );
};
