import React from "react";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  MarkdownField,
} from "@refinedev/mui";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation } from "@refinedev/core";

export const ConfiguredAgentList = () => {
  const { dataGridProps } = useDataGrid();

  const { show } = useNavigation();

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        minWidth: 50,
      },
      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 50,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "callsign",
        headerName: "Callsign",
        minWidth: 200,
      },
      {
        field: "os",
        headerName: "OS",
        minWidth: 50,
      },
      {
        field: "arch",
        headerName: "Arch",
        minWidth: 50,
      },
      // {
      //   field: "c2_pub_key",
      //   headerName: "C2 Pub Key",
      //   minWidth: 200,
      // },
      // {
      //   field: "c2_priv_key",
      //   headerName: "C2 Priv Key",
      //   minWidth: 200,
      // },
      // {
      //   field: "agent_pub_key",
      //   headerName: "Agent Pub Key",
      //   minWidth: 200,
      // },
      {
        field: "enc_config",
        headerName: "Enc Config",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <MarkdownField value={(value ?? "").slice(0, 80) + "..."} />;
        },
      },
      {
        field: "storage_key",
        headerName: "Storage Key",
        minWidth: 200,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        //disable hightlighting of the row when clicked
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        columns={columns}
        autoHeight
        onRowClick={({ id }) => {
          show("configured_agents", id);
        }}
      />
    </List>
  );
};
