import React, { useState, useEffect } from "react";
import { Card, CardHeader, Grid } from "@mui/material";
import { supabaseClient } from "utility";
import { Alert, AlertTitle, Box } from "@mui/material";
// import { CallbacksTasksTable } from "../../components/CallbacksTasksTable";
import CallbacksTasksTable from "../../components/CallbacksTasksTable";
import HeatMap from "../../components/heatmap";
import SummaryCountsTable from "components/summary";
import { AgentMap } from "../../components/map/";
export const DashboardPage: React.FC = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabaseClient.rpc("get_latest_lat_long");
      if (error) {
        console.error(error);
      } else {
        setRows(data.map((row: any, index: any) => ({ ...row, id: index })));
      }
    };
    fetchData();
  }, []);

  console.log("rows", rows);
  const latLongArray = rows.map((item: any) => ({
    tooltip: item.agent_id,
    coords: item.latest_lat_long,
    icon: "marker-iconlg.png",
  }));

  console.log("latLongArray", latLongArray);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      columns={24}
      spacing={2}
    >
      <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <center>
            Callbacks Heatmap
            <AgentMap mapCoords={latLongArray}></AgentMap>
            {/* <DailyOrders /> */}
          </center>
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={12} lg={12} xl={12} alignItems="center">
        <Card>
          Callbacks Heatmap
          <HeatMap ids={[]}></HeatMap>
          {/* Task HeatMap */}
          {/* <HeatMap ids={[]}></HeatMap> */}
          {/* <DailyOrders /> */}
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card>
          Summary
          <SummaryCountsTable></SummaryCountsTable>
          {/* <DailyOrders /> */}
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          Agent Totals
          <CallbacksTasksTable></CallbacksTasksTable>
          {/* <DailyRevenue /> */}
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={12} xl={7}>
        <Card>Misc{/* <NewCustomers /> */}</Card>
      </Grid>
    </Grid>
  );
};
