import React from "react";

import { Refine } from "@refinedev/core";

import {
  AuthPage,
  notificationProvider,
  RefineSnackbarProvider,
  ReadyPage,
  ErrorComponent,
} from "@refinedev/mui";

import { CssBaseline, GlobalStyles } from "@mui/material";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import { MuiInferencer } from "@refinedev/inferencer/mui";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { RefineKbarProvider } from "@refinedev/kbar";
import { supabaseClient } from "utility";
import { ColorModeContextProvider } from "contexts";
import { Title, Sider, Layout, Header } from "components/layout";
import { OffLayoutArea } from "components/offLayoutArea";
import authProvider from "./authProvider";

import { DashboardPage } from "./pages/dashboard";
import { CallbackShow } from "./pages/callbacks/show";
import { CallbackList } from "./pages/callbacks/list";
import { TaskingCreate } from "./pages/tasking/create";
import { TaskingShow } from "./pages/tasking/show";
import { TaskingList } from "./pages/tasking/list";
import { AgentTaskList } from "./pages/agent_tasks/list";
import { AgentTaskShow } from "./pages/agent_tasks/show";
import { ConfiguredAgentList } from "./pages/configured_agents/list";
import { ConfiguredAgentShow } from "./pages/configured_agents/show";
import { ConfiguredAgentCreate } from "./pages/configured_agents/create";
import { ReferenceAgentList } from "pages/reference_agents/list";
import { ReferenceAgentShow } from "pages/reference_agents/show";
import { AgentGroupList } from "pages/agent_group/list";
import { AgentGroupCreate } from "pages/agent_group/create";
import { AgentGroupShow } from "pages/agent_group/show";

import { SurillmiList } from "pages/surillmi/list";
import { SurillmiCreate } from "pages/surillmi/create";
import { SurillmiShow } from "pages/surillmi/show";

function App() {
  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <RefineKbarProvider>
          <Refine
            dataProvider={dataProvider(supabaseClient)}
            liveProvider={liveProvider(supabaseClient)}
            options={{ liveMode: "auto" }}
            legacyAuthProvider={authProvider}
            legacyRouterProvider={{
              ...routerProvider,
              routes: [
                {
                  path: "/register",
                  element: <AuthPage type="register" />,
                },
                {
                  path: "/forgot-password",
                  element: <AuthPage type="forgotPassword" />,
                },
                {
                  path: "/update-password",
                  element: <AuthPage type="updatePassword" />,
                },
              ],
            }}
            LoginPage={() => (
              <AuthPage
                type="login"
                providers={[]}
                formProps={{
                  defaultValues: {
                    email: "info@refine.dev",
                    password: "refine-supabase",
                  },
                }}
              />
            )}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            resources={[
              {
                name: "dashboard",
                list: DashboardPage,
              },
              {
                name: "callbacks",
                list: CallbackList,
                show: CallbackShow,
                canDelete: false,
              },
              {
                name: "configured_agents",
                list: ConfiguredAgentList,
                create: ConfiguredAgentCreate,
                show: ConfiguredAgentShow,
                canDelete: false,
              },
              {
                name: "reference_agents",
                list: ReferenceAgentList,
                show: ReferenceAgentShow,
                canDelete: false,
              },
              {
                name: "tasking",
                list: TaskingList,
                show: TaskingShow,
                create: TaskingCreate,
                canDelete: true,
              },
              {
                name: "agent_task",
                //list: AgentTaskList,
                show: AgentTaskShow,
                canDelete: false,
              },
              {
                name: "agent_group",
                list: AgentGroupList,
                show: AgentGroupShow,
                create: AgentGroupCreate,
              },
              {
                name: "surillmi",
                list: SurillmiList,
                show: SurillmiShow,
                create: SurillmiCreate,
              },
            ]}
            Title={Title}
            Sider={Sider}
            Layout={Layout}
            Header={Header}
            OffLayoutArea={OffLayoutArea}
          />
        </RefineKbarProvider>
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
