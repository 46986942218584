import React from "react";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  TagField,
  DateField,
} from "@refinedev/mui";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany, useNavigation } from "@refinedev/core";

export const TaskingList = () => {
  const { dataGridProps } = useDataGrid();
  const { show } = useNavigation();
  const { data: configuredAgentsData, isLoading: configuredAgentsIsLoading } =
    useMany({
      resource: "configured_agents",
      ids: [].concat(
        ...(dataGridProps?.rows?.map((item: any) => item?.configured_agents) ??
          [])
      ),
      queryOptions: {
        enabled: !!dataGridProps?.rows,
      },
    });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "configured_agents",
        headerName: "Configured Agents",
        minWidth: 300,
        renderCell: function render({ value }) {
          return configuredAgentsIsLoading ? (
            <>Loading...</>
          ) : (
            <>
              {value?.map((item: any, index: number) => (
                <TagField
                  key={index}
                  value={
                    configuredAgentsData?.data?.find(
                      (resourceItems) => resourceItems.id === item
                    )?.callsign || "DELETED"
                  }
                />
              ))}
            </>
          );
        },
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "task",
        headerName: "Task",
        type: "number",
        minWidth: 50,
      },
      {
        field: "arg",
        headerName: "Arg",
        minWidth: 50,
      },
      {
        field: "completed",
        headerName: "Completed",
        minWidth: 50,
      },
    ],
    [configuredAgentsData?.data]
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        //disable hightlighting of the row when clicked
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        columns={columns}
        autoHeight
        onRowClick={({ id }) => {
          show("tasking", id);
        }}
      />
    </List>
  );
};
