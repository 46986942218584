import { useShow, useMany, useNavigation } from "@refinedev/core";
import { Show, TagField, TextFieldComponent as TextField, DateField, NumberField } from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

import { TaskComponentList } from "../../components/tasks/";

export const TaskingShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const { show } = useNavigation();

  const record = data?.data;
  const { data: tagsData, isLoading: tagsIsLoading } = useMany({
    resource: "configured_agents",
    ids: record?.configured_agents || [],
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <TextField value={record?.id} />

        <Typography variant="body1" fontWeight="bold">
          Created At
        </Typography>
        <DateField value={record?.created_at} />
        <Typography variant="body1" fontWeight="bold">
          Task
        </Typography>
        <NumberField value={record?.task ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Arg
        </Typography>
        <TextField value={record?.arg} />
        {tagsIsLoading ? (
          <>Loading...</>
        ) : (
          <>
            <Stack direction="row" spacing={1}>
              {tagsData?.data?.map((tags: any) => (
                <TagField
                  key={tags?.callsign}
                  value={tags?.callsign}
                  onClick={() => {
                    show("configured_agents", tags?.id);
                  }}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Show>
  );
};
