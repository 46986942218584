import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

export const SurillmiCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        {/*
                    DatePicker component is not included in "@refinedev/mui" package.
                    To use a <DatePicker> component, you can follow the official documentation for Material UI.

                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                */}

        <TextField
          {...register("prompt", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.prompt}
          helperText={(errors as any)?.prompt?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Prompt"
          name="prompt"
        />
        <TextField
          {...register("posts", {
            required: "This field is required",
            valueAsNumber: true,
          })}
          error={!!(errors as any)?.posts}
          helperText={(errors as any)?.posts?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Posts"
          name="posts"
        />
        <TextField
          {...register("numWords", {
            required: "This field is required",
            valueAsNumber: true,
          })}
          error={!!(errors as any)?.numWords}
          helperText={(errors as any)?.numWords?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Num Words"
          name="numWords"
        />
        <TextField
          {...register("language", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.language}
          helperText={(errors as any)?.language?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Language"
          name="language"
        />
      </Box>
    </Create>
  );
};
